/** Class representing a form stepper.
 * Handles validating fields and submission
 * */
class FormStepper {
  constructor(form, formInputs, stringElementNames, floatElementNames) {
    this.form = form;
    this.pages = document.querySelectorAll('[class^="contact-form-page-"]');
    this.formInputs = Object.values(formInputs);
    this.fieldValues = {};
    this.stringElementNames = stringElementNames;
    this.floatElementNames = floatElementNames;
    this.currentPage = 0;
    this.currentPageElement = this.pages[this.currentPage];
    this.currentForwardButton = null;
    this.currentForwardButtonMessage = null;
    this.pageLoaded = new Event('pageLoaded');
    this.init();
  }

  init() {
    this.form.addEventListener('submit', this.onSubmit.bind(this));
    const backButtons = this.form.querySelectorAll('.back');

    backButtons.forEach(function (button) {
      button.addEventListener('click', this.goToPage.bind(this, -1));
    }, this);

    const forwardButtons = this.form.querySelectorAll('.forward');

    forwardButtons.forEach(function (button) {

      button.addEventListener('click', function (e) {
        const validationResults = this.validatePageInputs();

        if (validationResults === true) {
          this.goToPage(99);
        } else {
          // this.displayErrorMessage();
        }
      }.bind(this));
    }, this);

  }

  validateFields() {
    let invalidFields = [];

    this.formInputs.forEach((field) => {
      const valid = this.validateField(field);

      if (valid !== true) {
        invalidFields.push(field);
      }
    });

    return invalidFields;
  }

  validateField(field) {
    const value = field.value;

    if (!value) {
      return field;
    }

    return true;
  }

  setFieldValues() {
    this.formInputs.forEach((field) => {
      let name = field.getAttribute('name').replace(/-/g, '_');
      let value = field.value;

      if (this.stringElementNames.includes(name)) {
        value = parseFloat(field.value);

      } else if (!this.floatElementNames.includes(name)) {
        value = parseInt(field.value);
      }

      this.fieldValues[name] = value;
    });
  }

  removeErrors(inputs) {
    inputs.forEach((input) => {
      input.classList.remove('error');
    });
  }


  onSubmit(event) {
    event.preventDefault();
    this.setFieldValues();

    const invalidInputs = this.validateFields();

    if (invalidInputs.length > 0) {

      invalidInputs.forEach((input) => {
        input.classList.add('error');
      });
    }
  };

  /**
   * Displays the page with the class `class="calculator-page-1"` if you send in `1` and hides all other pages.
   * Send in `-1` to go backwards one page. Send in `99` to go forward one page
   * @param {number} indexValue - The number found after the `calculator-page-` part of the class.
   */
  goToPage(indexValue) {

    if (indexValue === -1) {
      const newPageValue = this.currentPage - 1;
      indexValue = newPageValue;
      this.currentPage = newPageValue;

    } else if (indexValue === 99) {
      const newPageValue = this.currentPage + 1;
      indexValue = newPageValue;
      this.currentPage = newPageValue;

    } else {
      this.currentPage = indexValue;
    }

    this.resetForwardButtonText();

    [...this.pages].map(function (pageElement, index) {

      if (index !== indexValue) {
        this.hidePage(pageElement);

      } else {
        this.showPage(pageElement);
        this.currentPageElement = pageElement;
        this.currentForwardButton = this.currentPageElement.querySelector('.forward');
        pageElement.dispatchEvent(this.pageLoaded);
        this.setForwardButtonText();
      }
    }, this);
  }

  showPage(pageElement) {
    pageElement.classList.add('show');
    pageElement.classList.remove('hide');
  }

  hidePage(pageElement) {
    pageElement.classList.add('hide');
    pageElement.classList.remove('show');
  }

  setForwardButtonText() {
    if(this.currentForwardButton === null){
      return;
    }

    const originalForwardButtonText = this.currentForwardButton.getAttribute('original-forward-text');

    if (originalForwardButtonText === '' || originalForwardButtonText === null) {
      this.currentForwardButton.setAttribute('original-forward-text', this.currentForwardButton.value);
      this.currentForwardButtonMessage = this.currentForwardButton.value;
    }
    this.currentForwardButton.value =
      this.currentForwardButtonMessage === null
        ? originalForwardButtonText
        : this.currentForwardButtonMessage;
  }

  resetForwardButtonText() {
    this.currentForwardButtonMessage = null;
    const originalForwardButtonText =
      this.currentForwardButton !== null
        ? this.currentForwardButton.getAttribute('original-forward-text')
        : null;

    if (originalForwardButtonText !== '' && originalForwardButtonText !== null) {
      this.currentForwardButton.value = originalForwardButtonText;
    }
  }

  validatePageInputs(pageNumber) {
    /*should be implemented in child class*/
  }
}
